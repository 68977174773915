.ep-nav {
    padding: 1em;
    background-color: $dark;
    i, mat-icon {
        font-size: 25px;
        width: auto;
        color: $white;
    }
}

app-nav {
    z-index: 23;
}