

html, body {
  height: 100%;
 }


@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "./css/abstracts/variables";
@import "./css/abstracts/mixins";
@import "./css/base/colors";
@import "./css/base/general";
@import "./css/base/typography";

@import "./css/components/accordion";
@import "./css/components/button";
@import "./css/components/canvas";
@import "./css/components/checkbox";
@import "./css/components/color-picker";
@import "./css/components/controls";
@import "./css/components/dialog";
@import "./css/components/editor";
@import "./css/components/ep-symbol";
@import "./css/components/form";
@import "./css/components/icon";
@import "./css/components/input";
@import "./css/components/nav";
@import "./css/components/popup";
@import "./css/components/print";
@import "./css/components/progress-bar";
@import "./css/components/radio-button";
@import "./css/components/tabs";
@import "./css/components/viewer";

@import "./css/layout/grid";

body { margin: 0; font-family: "Roboto", Arial, Helvetica, sans-serif; }
