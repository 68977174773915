.ep-input {
    width: 200px
}

.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused {
    background-color: transparent !important;
}

.ep-form-control-container {
    width: 100%;
    .ep-form-control {
        .additional-tools-span {
            min-width: 95px;
        }
        span {
            max-width: 50%;
            font-size: 15px;
        };
        input, select, textarea{
            font-size: 18px;
            line-height: 24px;
            height: 30px;
            padding: 1px 2px;
            -ms-box-sizing:content-box;
            -moz-box-sizing:content-box;
            box-sizing:content-box;
            -webkit-box-sizing:content-box; 
        }
        input, textarea {
            width: 230px;
        }
        select {
            width: 232px;

        }
        @include flex-row;
        @include flex-align-space-between-center
    }
    .select-box{
        overflow: hidden;
        position: relative;
      }
      .select-box:after{
        width: 0; 
        height: 0; 
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid $normal-greyblue;
        position: absolute;
        top: 40%;
        right: 2px;
        content: "";
        z-index: 999;
       }
      /* .select-box select{
        width: 220px;
        border: 0;
        position: relative;
        z-index: 99;
        background: none;
      } */
      
    textarea {
        width: 192px;
        max-height: 300px;
        resize: vertical;
    }
}

.ep-datepicker {
    .ep-datepicker-input {
        width: 205px !important;
    }
    button.mdc-icon-button{
        padding: 0;
        min-width: 0;
        width: 25px;
        height: 40px;
        flex-shrink: 0;
        line-height: 40px;
        border-radius: 50%;
        mat-icon {
            height: 36px;
            @include flex-row;
            @include flex-align-center-center
        }
    }
}

.mat-error {
    animation-name: growthY;
    animation-duration: 0.3s;
}
  
@keyframes growthY {
    from {transform: scaleY(0.2);}
    to {transform: scaleY(1);}
}

.date-picker-icon {
    background-color: $dark-greyblue;
    color: white;
}

.mat-datepicker-toggle {
    button{
        width: unset;
    }
}

.mat-mdc-text-field-wrapper.mdc-text-field:not(.mdc-text-field--disabled) {
    padding: 0;
    .mat-mdc-form-field-focus-overlay {
        display: none;
    }
}