.print-preview-image {
    width: 100%;
    height: auto;
}

.print-preview-page {
    margin: auto;
}

.print-preview-container {
    width: 100%;
    max-height: 420px;
    align-items: center;
    //background: lightgray;
    overflow: auto;
}


.pdf-container {
    position: absolute;
    top: 200vh;
    left: 200vw;    
}