span.mat-expansion-indicator {
   pointer-events: visiblefill !important; 
}

mat-expansion-panel-header {
   pointer-events: none;
   font-weight: 400 !important;
}

.clickable {
    pointer-events: auto;
}