body {
    margin: 0;
}

.main-body {
    min-height: 100vh;
}

body::-webkit-scrollbar {
    display: none;
}

.no-overflow-x {
    overflow-x: hidden;
}

.border-b {
    border-bottom: solid $dark-greyblue 1px;
}
.border-t {
    border-top: solid $dark-greyblue 1px;
}

.full-height {
    height: 100%;
}

.max-full-vh {
    max-height: 100vh;
}

.full-height-container {
    max-height: calc(100vh - 70px);
    overflow: auto;
}

.disabled {color: grey;
    pointer-events: none;
    cursor: default; 
}

.hidden {
    display: none;
}

.video-preview {
    height: 400px;
    width: 400px;
    position: relative;
    .play {
        position: absolute;
        left: 42%;
        z-index: 1;
    }
    .preview {
        z-index: -1;
        width: auto;
        height: 100%;
    }
}

.sample-project-style {
    margin-top: 3em !important;
    border-top: solid black 1px;
}

.ep-link {
    cursor: pointer;
    text-decoration: underline;
}

.underline {
    text-decoration: underline;
}

.inline-content {
    display: inline;
    margin-right: 5px;
}

.faq-border-box {
    border: 1px solid $grey;
}