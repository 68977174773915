.mat-dialog-content.fullsize-dialog {
    padding: 0;
}

.img-responsive {
    width: 100%;
    display: block;
}

.mdc-dialog--open .mat-mdc-dialog-surface {
    padding: 24px;
}