.editor-container {
    height: calc(100vh - 70px);
}

.additional-tools-toggle-button-container {
    position: absolute;
    z-index: 99;
}

.tools-closed {
    top: 135px;
    left: 56px;
}
