.mat-checkbox-label {
    font-family: Roboto, sans-serif;
}

.mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
    svg {
        color: white;
    }
    background-color: $dark-greyblue !important;
    border-color: $dark-greyblue !important;
}