@mixin flex-row {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
  }
  
  @mixin flex-column {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
  }
  
  @mixin flex-align-space-between-center {
    justify-content: space-between;
    place-content: center space-between;
    align-items: center;
  }
  
  @mixin flex-align-space-between-start {
    justify-content: space-between;
    place-content: flex-start space-between;
    align-items: flex-start;
  }
  
  @mixin flex-align-center-center {
    justify-content: center;
    place-content: center;
    align-items: center;
  }
  
  @mixin flex-align-start-center {
    justify-content: flex-start;
    place-content: center flex-start;
    align-items: center;
  }
  
  @mixin flex-align-end-center {
    justify-content: flex-end;
    place-content: center flex-end;
    align-items: center;
  }
  
  @mixin flex-align-center-end {
    justify-content: center;
    place-content: flex-end center;
    align-items: flex-end;
  }
  
  @mixin flex-align-space-around-center {
    justify-content: space-around;
    place-content: center space-around;
    align-items: center;
  }
  
  $gridsteps: (
    '-0': (0),
    '-s': (0.5em),
    '': (1em),
    '-2': (2em),
    '-3': (3em),
    '-4': (4em),
    '-5': (5em),
    '-6': (6em),
    '-7': (7em),
    '-8': (8em)
  );
  
  @mixin createGrid($prefix,$csselement) {
    @each $class, $value in $gridsteps {
      .#{$prefix}#{$class} {
        #{$csselement}: nth($value, 1);
      }
    }
  }
  
  @mixin createGridImportant($prefix,$csselement) {
    @each $class, $value in $gridsteps {
      .#{$prefix}#{$class} {
        #{$csselement}: nth($value, 1) !important;
      }
    }
  }
  
  /* create different width classes in steps of 1% */
  
  @mixin createWidth($default: 1) {
    @for $i from 0 through 100 {
      .w-#{$i} {
        width: $i * 1%;
      }
    }
  }
  /* create different line-height classes in steps of 1% */
  
  @mixin createLineHeight($default: 1) {
    @for $i from 0 through 100 {
      .lh-#{$i} {
        line-height: $i * 1px;
      }
    }
  }
  
  /// Responsive breakpoint manager
  /// @access public
  /// @param {String} $breakpoint - Breakpoint
  /// @requires $breakpoints
  @mixin respond-to($breakpoint) {
    $raw-query: map-get($breakpoints, $breakpoint);
  
    @if $raw-query {
      $query: if(
        type-of($raw-query) == 'string',
        unquote($raw-query),
        inspect($raw-query)
      );
  
      @media #{$query} {
        @content;
      }
    } @else {
      @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
    }
  }
  