.ep-single-form {
    width: 400px;
}

.ep-project-data-form-field {
    width: 360px;
    margin-right: 25px;
}
.ep-single-wide-form {
    width: 450px;
}

.pixel-ratio-preview {
    position: relative;
    div {
        transition: width 250ms cubic-bezier(0, 0, 0.2, 1);
        position: absolute;
        left: 0;
        top: 3.5em;
        height: 5px;
        background-color: green;
    }
}