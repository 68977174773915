@include createGrid(m-t,margin-top);
@include createGrid(m-r,margin-right);
@include createGrid(m-b,margin-bottom);
@include createGrid(m-l,margin-left);

@include createGrid(p-t,padding-top);
@include createGrid(p-r,padding-right);
@include createGrid(p-b,padding-bottom);
@include createGrid(p-l,padding-left);

@media only screen and (max-width: 600px) {
	@include createGrid(p-t-m,padding-top);
	@include createGrid(p-r-m,padding-right);
	@include createGrid(p-b-m,padding-bottom);
	@include createGrid(p-l-m,padding-left);

	@include createGrid(m-t-m,margin-top);
	@include createGrid(m-r-m,margin-right);
	@include createGrid(m-b-m,margin-bottom);
	@include createGrid(m-l-m,margin-left);

	@include createGridImportant(m-t-m-i,margin-top);
	@include createGridImportant(m-r-m-i,margin-right);
	@include createGridImportant(m-b-m-i,margin-bottom);
	@include createGridImportant(m-l-m-i,margin-left);
}

/* additional grid */

.padding 	{padding: 1em;}
.p-2 		{padding: 2em;}
.p-3 		{padding: 3em;}
.p-4 		{padding: 4em;}
.p-5 		{padding: 5em;}

.p-tb-s 	{padding: .5em 0;}
.p-tb 		{padding: 1em 0;}
.p-tb-2 	{padding: 2em 0;}
.p-tb-3 	{padding: 3em 0;}
.p-tb-4 	{padding: 4em 0;}
.p-tb-5 	{padding: 5em 0;}
.p-tb-6 	{padding: 6em 0;}
.p-tb-7 	{padding: 7em 0;}
.p-tb-8 	{padding: 8em 0;}

.p-l 		{padding-left: 1em;}
.p-lr 		{padding: 0 1em;}
.p-lr-2 	{padding: 0 2em;}
.p-lr-3 	{padding: 0 3em;}
.p-lr-4 	{padding: 0 4em;}
.p-lr-5 	{padding: 0 5em;}

.no-p 		{padding: 0}
.no-p-i		{padding: 0 !important}
.no-p-t 	{padding-top: 0;}
.no-p-b 	{padding-bottom: 0;}
.no-p-r 	{padding-right: 0;}
.no-p-l 	{padding-left: 0;}

/* margin */

.margin 	{margin: 1em;}
.m-2 		{margin: 2em;}
.m-3 		{margin: 3em;}
.m-4 		{margin: 4em;}
.m-5 		{margin: 5em;}

.m-tb 		{margin: 1em 0;}
.m-tb-2 	{margin: 2em 0;}
.m-tb-3 	{margin: 3em 0;}
.m-tb-4 	{margin: 4em 0;}
.m-tb-5 	{margin: 5em 0;}

.m-lr 		{margin: 0 1em;}
.m-lr-2 	{margin: 0 2em;}
.m-lr-3 	{margin: 0 3em;}
.m-lr-4 	{margin: 0 4em;}
.m-lr-5 	{margin: 0 5em;}

.no-m 		{margin: 0}
.no-m-t 	{margin-top: 0;}
.no-m-b 	{margin-bottom: 0;}
.no-m-r 	{margin-right: 0;}
.no-m-l 	{margin-left: 0;}

/* width classes w-1, w-2 ... w-100 */

@include createWidth;
@include createLineHeight;



