.mat-mdc-unelevated-button:not(:disabled).ep-btn-call {
    @extend .ep-btn;
    @extend .bg-dark-greyblue;
    font-weight: normal;
}

.mdc-button--outlined.ep-btn-system {
    @extend .font-dark;
    @extend .bg-light;
}

.mdc-button.mat-mdc-unelevated-button.ep-btn,
.mat-mdc-button-disabled  {
    span {
        @extend .font-white;
    }
}

button.mat-mdc-button,
.mdc-button.mat-mdc-unelevated-button,
.mdc-button--outlined {
    letter-spacing: normal !important;
}

button.disabled-ep, button.enabled-ep:hover  {
    position: relative;
    opacity: 0.5;
    mat-icon::after {
        position: absolute;
        //background: grey;
        opacity: 0.4;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: "";
    }
}

button.mdc-icon-button.mat-mdc-icon-button {
    width: 40px;
    height: 40px;
    padding: 0;
    .mat-mdc-button-touch-target {
        width: 40px;
        height: 40px;      
    }
}

.fill-color-btn {
    text-align: start !important;
}

.enabled-lock {
    position: relative;

}

.enabled-lock::after {
    position: absolute;
    background: green;
    width: 5px;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
}

.symbol-category-selection-trigger {
    width: 223px;
    height: 48px !important;
    .mat-button-wrapper {
        width: 100%;
        @include flex-row;
        @include flex-align-space-between-center
    }
}

.symbol-category-selection-trigger-text-container {
    max-width: 180px;
}

.symbol-category-selection-trigger-text {
    max-width: 180px;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
}

.door-icon {
    width: 29px;
    max-height: 29px;
    margin-right: 11px;
}
