#container {
    position: absolute;
    z-index: 1;
    background: transparent;
}

#vertical-ruler-container {
    position: relative;
    width: 30px;
    height: calc(100% - 30px);
    background: white;
    overflow: auto;
    #vertical-ruler {
        position: fixed;
        background: white;
    }
}

#horizontal-ruler-container {
    position: relative;
    padding-left: 30px;
    height: 30px;
    width: calc(100% - 30px);
    overflow: auto;
    #horizontal-ruler {
        position: fixed;
        background: white;
    }
}

.canvas-with-ruler {
   padding-left: 30px;
   padding-top: 30px;
}

#editor-viewport {
    position: relative;
    width: 100%;
}

.no-print {
    @media print { display:none }
}

.demo-stamp::after {
    pointer-events:none;
        content: "";
        background-image: url("/assets/images/demo-stamp.png");
        opacity: 0.3;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 22;   
}