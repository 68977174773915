/* Background-color: #F8F8FA
- Border: 1px solid #333333
- Padding: 40px;
- Background-position: center center */

.grid-setting-dialog {
    max-height: 70vh;
    overflow: auto;
    .dialog-color-picker {
        .sketch-picker {
            width: 335px;
        }
    }
}
