/* brakpoints for screen sizes */
$breakpoints: (
  'mobile': (max-width: 600px),
  'tablet': (max-width: 768px),
  'big-tablet': (max-width: 960px),
  'medium-screen' : (max-width: 1280px),
  'large-screen' : (max-width: 1920px),
  'extra-large-screen' : (min-width: 1920px)
);

/* colors */
$grey: #707070;
$light-grey: #aaa;
$dark: #333;
$white: #fff;
$light: #f8f8fa;
$light-greyblue:#ADBAD4;
$normal-greyblue:#61759C;
$dark-greyblue:#003151;