    .horizontal-control-panel {
        background: lightgray;
        padding: 10px;
        overflow-x: auto;
    }
    .vertical-control-panel {
        background: lightgray;
        padding: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 130px);
        width: 60px;
    }

    .vertical-control-panel::-webkit-scrollbar {
        display: none;
    }


/*     .vertical-controls-container {
        overflow: auto;
    } */

    .horizontal-controls-container, .vertical-controls-container {
        z-index: 100;
        position: relative;
    }

    .vertical-controls-container {
        .additional-tools-container {
            z-index: 100;
            border-left: solid #999 1px;
            position: absolute;
            top: 0;
            left: 60px;
            height: 100%;
            overflow: auto;
            background: lightgray;
            .additional-tools {
                height: 100%;
                .additional-tools-element {
                    min-width: 300px;
                }
            }
        }
    }

    .active-layer {
        background: $light !important;
    }

    .layer-drag-placeholder {
        background: #ccc;
        border: dotted 3px #999;
        min-height: 20px;
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
      