.ep-symbol {
    max-width: 70px;
    max-height: 70px;
    object-fit: contain;
}

.ep-symbol-title {
    width: 90px;
    text-align: center;
    font-size: 12px;
    max-height: 60px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    display: box;
    box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
}

.ep-symbol-container {
    min-width: 45px;
    min-height: 45px;
    height: fit-content;
    width: fit-content;
    position: relative;
    .symbol-delete-button {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
}

.symbols-form-field {
    .mat-form-field-infix {
        width: 210px;
    }
}