.font-grey{
    color: $grey;
}
.font-dark {
    color: $dark;
}
.font-white {
    color: $white;
}
.font-light {
    color: $light;
}
.font-light-greyblue {
    color: $light-greyblue;
}
.font-normal-greyblue {
    color: $normal-greyblue;
}
.font-dark-greyblue {
    color: $dark-greyblue;
}
.font-warning {
    color: red;
}

.bg-grey{
    background-color: $grey;
}
.bg-dark {
    background-color: $dark;
}
.bg-white {
    background-color: $white;
}
.bg-light {
    background-color: $light;
}
.bg-light-grey {
    background-color: $light-grey;
}
.bg-light-greyblue {
    background-color: $light-greyblue;
}
.bg-normal-greyblue {
    background-color: $normal-greyblue;
}
.bg-dark-greyblue {
    background-color: $dark-greyblue;
}
