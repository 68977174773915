body {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-weight: Regular;
    font-style: normal;
}
  
  // Font Sizes
span,p,a,input, mat-input {
    font-size: 18px;
    line-height: 24px;
}

.small-text {
    font-size: 15px;
}

mat-icon {
    font-family: "Material Icons";
} 

span,p {
    color: $dark;
}
a {
    color: $dark-greyblue;
}

h1,h2,h3 {
    color: $grey;
}

h1 {
    font-size: 35px;
    line-height: 44px;
    @include respond-to('mobile') {
        font-size: 26px;
    }
}

h2 {
    font-size: 30px;
    line-height: 39px;
    font-weight: normal;
    @include respond-to('mobile') {
        font-size: 20px;
    }
}

h3 {
    font-size: 25px;
    line-height: 33px;
    font-weight: normal;
    @include respond-to('mobile') {
        font-size: 14px;
    }
}

.text-center {
    text-align: center;
}

textarea {
    font-size: 18px;
    line-height: 24px;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
}