.ep-tabs-labels {
    border-right: solid 1px $light-greyblue;
    min-width: 220px;
    @include respond-to('big-tablet') {
        border-right: none;
    }
}

.ep-label-wrapper {
    position: relative;
    width: 100%;
    button {
        width: 100%;;
    }
}

.ep-tabs-content-container{
    padding: 1em;
    height: 80vh;
    overflow-x: auto;
}

.active-tab {
    color: black;
    background-color: $light;
}

.active-tab::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 2px;
    background-color: $light-greyblue;
}

.ep-tab-content {
    height: 100%;
}

.ep-tab-content, .active-tab::after {
    animation-name: growth;
    animation-duration: 0.3s;
}

.ep-tab-content > div {
    max-height: 100%;
    @include respond-to('medium-screen') {
        max-height: 200%;
    }
}
  
  @keyframes growth {
    from {transform: scaleX(0.2);}
    to {transform: scaleX(1);}
  }